
import { defineComponent, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { translate } from "@/core/services/HelpersFun";
import { hideModal } from "@/core/helpers/dom";
import { checkAutoLogin, getPreviousToken } from "@/core/services/AutoLogin";
import { AxiosResponse } from "axios";
import UserInfo from "@/core/services/UserInfo";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
  name: "dialog-copy-tag",
  props: {
    bot_id_is: {
      type: String,
      default: "",
    },
    tag: {
      type: String,
      default: "",
    },
  },
  emits: ["update"],
  setup(props, { emit }) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const copyTagModal = ref<null | HTMLElement>(null);
    const user = UserInfo.getUser();

    const data = ref({
      source_bot_id: props.bot_id_is,
      source_tag: props.tag,
      destination_bot_id: "",
      destination_tag: "",
    });

    watch(props, () => {
      data.value.source_bot_id = props.bot_id_is;
      data.value.source_tag = props.tag;
    });

    const botList = ref();
    const getItems = () => {
      let endPointUrl = "bot";
      ApiService.query(endPointUrl, {})
        .then(({ data }) => {
          botList.value = data.data.bots.data;
        })
        .catch(() => {
          // ElNotification({
          //   title: "Info",
          //   message: "خطا در بارگزاری لیست ربات ها",
          //   type: "info",
          // });
        });
    };

    onMounted(() => {
      getItems();
    });

    const store = useStore();

    const copy = () => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.disabled = true;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      let request: Promise<AxiosResponse>;
      if (checkAutoLogin()) {
        let config = {
          headers: {
            Authorization: `Bearer ${getPreviousToken()}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
        request = ApiService.post(
          `admin/bot/bot-menu/copy-menus`,
          data.value,
          config
        );
      } else {
        let endPoint = "bot/bot-menu/copy-menus";
        request = ApiService.post(endPoint, data.value);
      }
      request
        .then(() => {
          hideModal(copyTagModal.value);
          emit("update");
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
        });
    };

    return {
      copy,
      checkAutoLogin,
      translate,
      data,
      botList,
      submitButtonRef,
      copyTagModal,
      user,
    };
  },
});
