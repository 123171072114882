import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "org-tree-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlocksNode = _resolveComponent("BlocksNode")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["org-tree", { horizontal: _ctx.horizontal, collapsable: _ctx.collapsable }])
    }, [
      _createVNode(_component_BlocksNode, {
        "parent-id": _ctx.parentId,
        data: _ctx.data,
        props: _ctx.props,
        horizontal: _ctx.horizontal,
        "label-width": _ctx.labelWidth,
        collapsable: _ctx.collapsable,
        "render-content": _ctx.renderContent,
        "label-class-name": _ctx.labelClassName,
        onNodeExpand: _cache[0] || (_cache[0] = 
          (e, data, nodeContext) => _ctx.$emit('node-expand', e, data, nodeContext)
        ),
        onNodeFocus: _cache[1] || (_cache[1] = 
          (e, data, nodeContext) => _ctx.$emit('node-focus', e, data, nodeContext)
        ),
        onNodeClick: _cache[2] || (_cache[2] = 
          (e, data, nodeContext) => _ctx.$emit('node-click', e, data, nodeContext)
        ),
        onAddClick: _cache[3] || (_cache[3] = 
          (e, data, nodeContext) => _ctx.$emit('add-click', e, data, nodeContext)
        ),
        onEditClick: _cache[4] || (_cache[4] = 
          (e, data, nodeContext) => _ctx.$emit('edit-click', e, data, nodeContext)
        ),
        onDeleteClick: _cache[5] || (_cache[5] = 
          (e, data, nodeContext) => _ctx.$emit('delete-click', e, data, nodeContext)
        ),
        onMouseOver: _cache[6] || (_cache[6] = 
          (e, data, nodeContext) => _ctx.$emit('mouse-over', e, data, nodeContext)
        ),
        onNodeMouseover: _cache[7] || (_cache[7] = 
          (e, data, nodeContext) =>
            _ctx.$emit('node-mouseover', e, data, nodeContext)
        ),
        onNodeMouseout: _cache[8] || (_cache[8] = 
          (e, data, nodeContext) => _ctx.$emit('node-mouseout', e, data, nodeContext)
        )
      }, {
        node: _withCtx(({ data, context }) => [
          _renderSlot(_ctx.$slots, "node", {
            data: data,
            context: context
          })
        ]),
        _: 3
      }, 8, ["parent-id", "data", "props", "horizontal", "label-width", "collapsable", "render-content", "label-class-name"])
    ], 2)
  ]))
}