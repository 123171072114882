
import { platforms } from "@/store/pinia/Platforms";
import { storeToRefs } from "pinia/dist/pinia";
import { checkPackagePlatform } from "@/core/services/HelpersFun";
import { useI18n } from "vue-i18n";
import ApiService from "../core/services/ApiService";
import { ElNotification } from "element-plus";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { onMounted,ref, watch } from "vue";

export default {
  name: "SecretaryPower-kt",
  props: {
    bot: {
      type: Object,
    },
    enable_platforms: {
      type: Array,
    },
    tag: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const store = useStore();
    const btn_status = ref()
    

    const platformHolder = platforms();
    const { platformsList } = storeToRefs(platformHolder);

    onMounted(() => {
      btn_status.value = props.enable_platforms;

    });

    watch(props, () => {
      btn_status.value = props.enable_platforms;
      
    });


    const handleSecretaryPlatformStatus = (bot_id, tag, platform, status) => {
      ApiService.post(`bot/${props.bot.bot_id}/toggle-secretary`, {
        tag: props.tag,
        platform: platform,
        status: status,
      })
        .then(() => {
          emit("getItem");
          ElNotification({
            title: translate("done"),
            message: translate("mission accomplished"),
            type: "success",
          });
        })
        .catch(({ response }) => {
          store.commit(Mutations.SET_ERROR, response.data);
        });
    };

    return {
      platformsList,
      checkPackagePlatform,
      translate,
      handleSecretaryPlatformStatus,
      btn_status,
    };
  },
};
