
import BlocksNode from "./blocks-node.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "blocks-tree",
  components: {
    BlocksNode,
  },
  props: {
    parentId: {
      type: Number,
      default: undefined,
    },
    data: {
      type: Object,
      required: true,
    },
    props: {
      type: Object,
      default: () => ({
        label: "label",
        expand: "expand",
        children: "children",
        key: "id",
      }),
    },
    horizontal: Boolean,
    collapsable: Boolean,
    renderContent: Function,
    labelWidth: [String, Number],
    labelClassName: [Function, String],
  },
});
