
import {
  computed,
  defineComponent,
  ref,
  watch,
  onMounted,
  onUpdated,
} from "vue";
import type { ElUpload } from "element-plus";
import { useI18n } from "vue-i18n";
import { UploadFilled } from "@element-plus/icons-vue";
import FileViewer from "@/components/FileViewer.vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
// import { create } from "yup/lib/Reference";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "kt-bulks",
  components: { FileViewer },
  props: {
    classes: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "upload/file",
    },
    type: {
      type: String,
      default: "btn",
    },
    check: {
      type: Number,
    },
    title: {
      type: String,
      default: "send file",
    },
    icon: {
      type: String,
      default: "bi-paperclip",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number || String,
      default: 100000000,
    },
    file: {
      type: String,
      default: "",
    },
    fileType: {
      type: String,
      default: "",
    },
    supportRules: {
      type: Object,
      default: [] as any,
    },
  },
  emits: ["uploadSuccess", "uploading"],

  setup: function (props, { emit }) {
    const acceptType = ref("*.*");
    const uploading_status = ref({
      status: false,
      uploadStatus: {
        name: "",
        status: "",
        percentage: 0,
      },
      filesHolder: [] as any,
    });
    const route = useRoute();

    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const uploadRef = ref<InstanceType<typeof ElUpload>>();

    const uploadedFiles = ref({
      mime: "", // image/jpeg, image/png, image/gif, video/mp4, video/3gpp, application/pdf, application/zip, application/vnd.rar, audio/mpeg, audio/ogg
      only_as_file: false,
      path: "",
      origin_file_name: "",
      size: 0,
      file_type: "", // image - video - audio - file - ...
      url: "",
      excel_phone_number_count: 0,
    });

    const selectedFilesType = (type) => {
      if (type.match("image.*")) return "image";
      if (type.match("video.*")) return "video";
      if (type === "audio/ogg" || type === "audio/mpeg") return "audio";
      return "file";
    };

    const submitUpload = () => {
      uploadRef.value!.submit();
    };

    const uploadFilesCallback = (res) => {
      uploadedFiles.value.mime = res.data.mime;
      uploadedFiles.value.only_as_file = res.data.only_as_file;
      uploadedFiles.value.path = res.data.path;
      uploadedFiles.value.origin_file_name = res.data.origin_file_name;
      uploadedFiles.value.size = res.data.size;
      uploadedFiles.value.file_type =
        acceptType.value === "*.*" ? "file" : selectedFilesType(res.data.mime);
      uploadedFiles.value.url = res.data.url;
      uploadedFiles.value.excel_phone_number_count =
        res.data?.payload.valid_numbers;

      emit("uploadSuccess", uploadedFiles.value, props.index);

      uploading_status.value.status = false;
      uploading_status.value.uploadStatus.status = "success";
      uploading_status.value.uploadStatus.percentage = 100;
    };

    const removeFile = () => {
      uploadedFiles.value.mime = "";
      uploadedFiles.value.only_as_file = false;
      uploadedFiles.value.path = "";
      uploadedFiles.value.size = 0;
      uploadedFiles.value.file_type = "message";
      uploadedFiles.value.url = "";

      emit("uploadSuccess", uploadedFiles.value, props.index);

      uploading_status.value.status = false;
      uploading_status.value.uploadStatus.status = "error";
      uploading_status.value.uploadStatus.percentage = 0;
    };

    const store = useStore();

    const errorHolder = ref();
    const uploadErrorCallback = (error) => {
      uploading_status.value.status = false;
      errorHolder.value = JSON.parse(error.message);
      if (errorHolder.value)
        store.commit(Mutations.SET_ERROR, errorHolder.value);
    };

    const onProgress = (evt, uploadFile, uploadFiles) => {
      uploading_status.value.status = true;
      uploading_status.value.filesHolder = uploadFiles;
      uploading_status.value.uploadStatus = uploadFile;
    };

    const onRemove = () => {
      uploading_status.value.status = false;
    };

    const uploadActionUrl = () => {
      return process.env.VUE_APP_UPLOAD_FILE_URL + props.url;
    };

    watch(uploading_status.value, () => {
      emit("uploading", uploading_status.value.status, props.index);
    });

    const showRules = computed(() => {
      return props.supportRules;
    });

    onMounted(() => {
      if (route.name === "مدیریت پاسخوگی خودکار") {
        uploadedFiles.value.url = props.file;
      }
    });
    onUpdated(() => {
      if (route.name === "مدیریت پاسخوگی خودکار") {
        uploadedFiles.value.url = props.file;
      }
    });

    return {
      selectedFilesType,
      uploadRef,
      submitUpload,
      uploadActionUrl,
      uploadFilesCallback,
      uploadErrorCallback,
      translate,
      onProgress,
      onRemove,
      UploadFilled,
      uploading_status,
      acceptType,
      uploadedFiles,
      removeFile,
      showRules,
      errorHolder,
    };
  },
});
