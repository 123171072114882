<template>
  <div>
    <div class="card">
      <div class="card-header bg-white d-flex flex-wrap">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">
            {{ translate("Autoresponder") }} :
            <span class="text-primary">{{ defaultData.tag }}</span></span
          >
        </h3>
        <div class="d-flex flex-wrap justify-content-center align-items-center">
          <SecretaryPower
            class="mb-2"
            v-if="secretaryList && defaultData.bot && defaultData.bot.package"
            :bot="defaultData.bot"
            :enable_platforms="activeSecretary.enable_platforms"
            :tag="defaultData.tag"
            @getItem="fetchSecretary"
          />
          <el-tooltip placement="top" :content="translate('display')">
            <button
              class="btn btn-icon btn-bg-light btn-active-warning btn-sm me-1"
              active-class="active"
              @click="
                defaultData.treeOrientation = !defaultData.treeOrientation
              "
            >
              <span class="svg-icon svg-icon-3">
                <i
                  class="bi"
                  :class="
                    defaultData.treeOrientation
                      ? 'bi-box-arrow-right'
                      : 'bi-box-arrow-down'
                  "
                ></i>
              </span>
            </button>
          </el-tooltip>
          <el-tooltip
            placement="top"
            :content="translate('Collapse|Expand menu')"
          >
            <button
              class="btn btn-icon btn-bg-light btn-active-warning btn-sm me-1"
              active-class="active"
              @click="defaultData.collapsable = !defaultData.collapsable"
            >
              <span class="svg-icon svg-icon-3">
                <i
                  class="bi"
                  :class="
                    defaultData.collapsable === true
                      ? 'bi-arrows-collapse'
                      : 'bi-arrows-expand'
                  "
                ></i>
              </span>
            </button>
          </el-tooltip>
          <el-tooltip placement="top" :content="translate('copy')">
            <button
              class="text-nowrap btn btn-sm btn-light btn-active-light-info  me-1 mb-1"
              active-class="active"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_copy_tag"
            >
              {{ translate("copy") }}
            </button>
          </el-tooltip>

          <el-tooltip placement="top" :content="translate('delete')">
            <el-popconfirm
              width="220"
              :confirm-button-text="translate('yes')"
              :cancel-button-text="translate('cancel')"
              icon-color="#626AEF"
              :title="
                translate(
                  'You are deleting the selected Autoresponder are you sure'
                )
              "
              @confirm="deleteSecretary(defaultData.bot_id, defaultData.tag)"
            >
              <template #reference>
                <button
                  class="text-nowrap btn btn-sm btn-light btn-active-light-danger  me-1 mb-1"
                  active-class="active"
                >
                  {{ translate("delete") }}
                </button>
              </template>
            </el-popconfirm>
          </el-tooltip>

          <el-tooltip placement="top" :content="translate('back')">
            <router-link
              to="/services/Secretary"
              class="text-nowrap btn btn-sm btn-light btn-active-light  me-1 mb-1"
              active-class="active"
            >
              {{ translate("back") }}
            </router-link>
          </el-tooltip>
        </div>
      </div>

      <div
        class="card-body card-scroll mh-400px min-h-350px text-center scrollable-element"
        style="overflow: scroll"
      >
        <blocks-tree
          :data="treeData"
          :horizontal="defaultData.treeOrientation"
          :collapsable="defaultData.collapsable"
          @add-click="addClick"
          @edit-click="editClick"
          @delete-click="deleteClick"
          @mouse-over="mouseOver"
        ></blocks-tree>
      </div>
    </div>
    <EditSecretaryTag
      @update="getSecretary"
      :bot_id="defaultData.bot_id"
      :tag="defaultData.tag"
    />
    <CopySecretary
      @update="getSecretary"
      :bot_id_is="defaultData.bot_id"
      :tag="defaultData.tag"
    />
    <ChangeSettings
      @update="getSecretary"
      :bot_id="defaultData.bot_id"
      :tag="defaultData.tag"
      footer=""
    />
    <CrudSecretaryMenuModal
      ref="crudComponentRef"
      @editMenu="editMenu"
      @submitMenu="createMenu"
      :editData="defaultData.editData"
      :type="defaultData.type"
      :parentId="defaultData.parent_id"
    ></CrudSecretaryMenuModal>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watchEffect } from "vue";
import { ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n/index";
import BlocksTree from "@/views/pages/services/secretary/component/blocks-tree.vue";
import CrudSecretaryMenuModal from "@/views/pages/services/secretary/component/CrudSecretaryMenuModal.vue";
import EditSecretaryTag from "@/views/pages/services/secretary/simpleComponents/EditSecretaryTag.vue";
import ChangeSettings from "@/views/pages/services/secretary/simpleComponents/ChangeSettings.vue";
import CopySecretary from "@/views/pages/services/secretary/simpleComponents/CopySecretaryTag.vue";
import { setCurrentPageBreadcrumbs } from "../../../../core/helpers/breadcrumb";
import SecretaryPower from "../../../../components/SecretaryPower";
import { Secretary } from "../../../../store/pinia/Secretary";
import { storeToRefs } from "pinia/dist/pinia";
export default defineComponent({
  components: {
    BlocksTree,
    CrudSecretaryMenuModal,
    EditSecretaryTag,
    ChangeSettings,
    CopySecretary,
    SecretaryPower,
  },
  setup() {
    let submitButtonDisabled = ref(false);
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const store = useStore();
    const route = useRoute();
    const defaultData = ref({
      bot_id: route.params.bot_id,
      tag: route.query.tag,
      bot: {},
      type: "",
      parent_id: undefined,
      selected: [],
      treeOrientation: false,
      collapsable: false,
      editData: {},
      botSetting: {
        secretary_footer: "",
      },
    });

    // secretary info holder
    const treeData = ref({
      label: translate("Welcome message"),
      expand: false,
      id: undefined,
    });

    const disableAllSecretary = ref(false);

    const getBot = () => {
      ApiService.query(
        `bot/${defaultData.value.bot_id}?with_platforms=true`,
        {}
      )
        .then(({ data }) => {
          defaultData.value.bot = data.data.bot;
        })
        .catch(() => {
          ElNotification({
            title: translate("error"),
            message: translate("failed"),
            type: "error",
          });
        });
    };

    const getSecretary = () => {
      treeData.value = {};
      defaultData.value.parent_id = undefined;
      ApiService.query(`bot/${defaultData.value.bot_id}/secretary-tree`, {
        params: { tag: defaultData.value.tag },
      })
        .then(({ data }) => {
          if (!data.data.tree.length) {
            treeData.value = {
              label: translate("Start creating a menu"),
              expand: false,
              id: undefined,
            };
            return;
          }
          for (const key in data.data.tree) {
            let tmpData = data.data.tree[key];
            treeData.value = convertToTreeStructure(tmpData);
          }
        })
        .catch((response) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
          ElNotification({
            title: translate("error"),
            message: translate("Error loading menus"),
            type: "error",
          });
        });
    };

    onMounted(() => {
      getBot();
      getSecretary();
      setCurrentPageBreadcrumbs(translate("edit"), [
        translate("Autoresponder"),
      ]);
    });

    const addClick = (e, data) => {
      defaultData.value.type = "create";
      defaultData.value.parent_id = data.id;
      defaultData.value.editData ={
          title: "",
          menu_key: "",
          send_message_after: 0,
          sort: 0,
          check: '',
          action: [
            {
              type: "text",
              text: "",
              file: undefined,
              with_file: false,
            },
          ],
        };;
    
    };

    const deleteClick = (e, data) => {
      deleteNode(data, treeData.value);
    };

    const mouseOver = (e, data) => {
      return data;
    };

    const convertToTreeStructure = (menuObject) => {
      let children = [];
      if (menuObject.children) {
        menuObject.children.forEach((item) => {
          children.push(convertToTreeStructure(item));
        });
      }
      let label = "";
      if (menuObject.is_default) label = translate("Welcome message");
      else label = `${menuObject.menu_key}-${menuObject.title}`;

      return {
        id: menuObject.id,
        label,
        expand: false,
        children: children,
        menuObject,
      };
    };

    const tryAddLeaf = (parentId, tree, label) => {
      let isParent = tree.id === parentId;
      if (isParent) {
        let id = (Math.random() * 100).toFixed();
        let leaf = {
          label: label,
          id: id,
        };
        if (!tree.children) {
          tree.expand = true;
          tree.children = [];
        }
        tree.children.push(leaf);
      } else if (tree.children) {
        tree.children.forEach((ch) => tryAddLeaf(parentId, ch, label));
      }
    };

    const deleteNode = (node) => {
      if (!confirm(translate("Are you sure to delete this menu?"))) return;

      ApiService.post(
        `bot/${defaultData.value.bot_id}/bot-menu/${node.id}/delete`,
        {}
      )
        .then(() => {
          getSecretary();
        })
        .catch((e) => {
          store.commit(Mutations.SET_ERROR, e.response.data);
          ElNotification({
            title: translate("error"),
            message: e.response.data.message,
            type: "error",
          });
        })
        .finally(() => (submitButtonDisabled.value = false));
    };

    const editClick = (e, item) => {
      defaultData.value.type = "edit";
      defaultData.value.editData = item.menuObject;
      defaultData.value.parent_id = item.menuObject.parent_id;
    };

    const createMenu = (menuData) => {
      submitButtonDisabled.value = true;
      menuData = JSON.parse(JSON.stringify(menuData));

      let data = {
        parent_id: menuData.parent_id,
        send_message_after: menuData.send_message_after,
        title: menuData.title,
        menu_key: menuData.menu_key,
        sort: menuData.sort,
        is_default: menuData.parent_id ? undefined : 1,
        tag: defaultData.value.tag,
        add_to_phonebook: menuData.add_to_phonebook,
        action: menuData.action,
      };

      ApiService.post(`bot/${defaultData.value.bot_id}/bot-menu/create`, data)
        .then(({ data }) => {
          let menu = data.data.menu;
          if (menu.is_default) {
            treeData.value.id = menu.id;
            treeData.value.label = menu.tag;
            treeData.value.expand = false;
          }
          getSecretary();
        })
        .catch((e) => {
          store.commit(Mutations.SET_ERROR, e.response.data);
          ElNotification({
            title: translate("error"),
            message: translate("Error in creating menu"),
            type: "error",
          });
        })
        .finally(() => (submitButtonDisabled.value = false));
    };

    const editMenu = (id, menuData) => {
      submitButtonDisabled.value = true;
      menuData = JSON.parse(JSON.stringify(menuData));
      let data = {
        send_message_after: menuData.send_message_after,
        parent_id: menuData.parent_id,
        title: menuData.title,
        menu_key: menuData.menu_key,
        sort: menuData.sort,
        is_default: menuData.parent_id ? undefined : 1,
        add_to_phonebook: menuData.add_to_phonebook,
        action: menuData.action,
      };
      ApiService.post(
        `bot/${defaultData.value.bot_id}/bot-menu/${id}/edit`,
        data
      )
        .then((result) => {
          let menu = result.data.data.menu;
          if (menu.is_default) {
            treeData.value.id = menu.id;
            treeData.value.label = menu.tag;
            treeData.value.expand = false;
          }
          getSecretary();
        })
        .catch((e) => {
          store.commit(Mutations.SET_ERROR, e.response.data);
          ElNotification({
            title: translate("error"),
            message: translate("Error in creating menu"),
            type: "error",
          });
        })
        .finally(() => (submitButtonDisabled.value = false));
    };

    const secretaryHolder = Secretary();
    const { secretaryList } = storeToRefs(secretaryHolder);
    const { fetchSecretary, deleteSecretary } = secretaryHolder;

    const activeSecretary = computed(() => {
      if (!secretaryList.value) return [];
      let findBot = secretaryList.value.filter(
        (item) => item.bot.bot_id === defaultData.value.bot_id
      );

      return findBot[0]["secretaries"].find(
        (item) => item.title === defaultData.value.tag
      );
    });

    onMounted(async () => {
      if (!secretaryList.value) await fetchSecretary("Autoresponder");
    });

    return {
      treeData,
      submitButtonDisabled,
      disableAllSecretary,
      defaultData,

      editMenu,
      addClick,
      editClick,
      deleteClick,
      mouseOver,
      createMenu,
      translate,
      getSecretary,

      deleteSecretary,
      activeSecretary,
      fetchSecretary,
      secretaryList,
    };
  },
});
</script>
