import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-items-center"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "symbol symbol-30px" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return ($props.bot && $props.bot.package && $props.bot.platforms)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.bot.platforms, (plat, platIndex) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: platIndex }, [
            (
          $setup.checkPackagePlatform($props.bot.package.platforms, plat.platform) &&
          $setup.platformsList[plat.platform].secretary_status
        )
              ? (_openBlock(), _createBlock(_component_el_tooltip, {
                  key: 0,
                  effect: "light",
                  placement: "top",
                  content: 
          $setup.btn_status?.includes(plat.platform)
            ? $setup.translate('Turn off the secretary') +
              ' ' +
              $setup.translate(plat.platform)
            : $setup.translate('Activate Autoresponder') +
              ' ' +
              $setup.translate(plat.platform)
        
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("button", {
                      class: _normalizeClass(["btn btn-outline border border-2 rounded rounded-pill d-flex flex-column align-items-center justify-content-between pt-1 pb-1 pe-1 me-1 btn-hover-scale", 
            $setup.btn_status?.includes(plat.platform)
              ? 'opacity-100 btn-outline-success bg-light-success border-success border-dashed'
              : 'opacity-50 btn-outline-light btn-active-light'
          ]),
                      onClick: ($event: any) => (
            $setup.handleSecretaryPlatformStatus(
              $props.bot.bot_id,
              $props.tag,
              plat.platform,
              !$setup.btn_status?.includes(plat.platform)
            )
          )
                    }, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("img", {
                          src: plat.platform_avatar,
                          alt: ""
                        }, null, 8, _hoisted_4)
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass(
              $setup.btn_status?.includes(plat.platform)
                ? 'text-success fs-8'
                : 'text-gray-700 fs-8'
            )
                      }, _toDisplayString($setup.btn_status?.includes(plat.platform) ? $setup.translate("on") : $setup.translate("off")), 3),
                      _createElementVNode("div", {
                        class: _normalizeClass(["w-30px h-30px d-flex justify-content-center align-items-center rounded rounded-circle", 
              $setup.btn_status?.includes(plat.platform)
                ? 'bg-success text-white'
                : 'bg-light text-muted  border border-gray-400 border-dashed'
            ])
                      }, [
                        _createElementVNode("i", {
                          class: _normalizeClass(["fa fa-power-off fs-4", 
                $setup.btn_status?.includes(plat.platform)
                  ? 'bg-success text-white'
                  : 'bg-light text-muted'
              ])
                        }, null, 2)
                      ], 2)
                    ], 10, _hoisted_2)
                  ]),
                  _: 2
                }, 1032, ["content"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}