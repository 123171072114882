import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  ref: "copyTagModal",
  id: "kt_modal_copy_tag",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-300px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = {
  class: "scroll-y me-n7 pe-7",
  id: "kt_modal_copy_tag_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_modal_copy_tag_header",
  "data-kt-scroll-wrappers": "#kt_modal_copy_tag_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_6 = { class: "row" }
const _hoisted_7 = {
  key: 0,
  class: "mb-5"
}
const _hoisted_8 = { class: "form-label fw-bold" }
const _hoisted_9 = ["aria-label"]
const _hoisted_10 = ["value"]
const _hoisted_11 = {
  key: 1,
  class: "mb-5"
}
const _hoisted_12 = { class: "form-label fw-bold" }
const _hoisted_13 = { class: "mb-5 fv-row" }
const _hoisted_14 = { class: "form-label mb-3" }
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = { class: "d-flex justify-content-end" }
const _hoisted_17 = {
  type: "reset",
  "data-bs-dismiss": "modal",
  class: "btn btn-sm btn-white btn-active-light-primary me-2",
  "data-kt-menu-dismiss": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_ctx.user.type === _ctx.user)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.translate("Choose a subscription")), 1),
                    _withDirectives(_createElementVNode("select", {
                      class: "form-select",
                      "aria-label": _ctx.translate('Choose a subscription'),
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.destination_bot_id) = $event))
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.botList, (bot) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: bot.bot_id,
                          value: bot.bot_id
                        }, _toDisplayString(bot.name), 9, _hoisted_10))
                      }), 128))
                    ], 8, _hoisted_9), [
                      [_vModelSelect, _ctx.data.destination_bot_id]
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.translate("Choose a subscription")), 1),
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.data.destination_bot_id,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.destination_bot_id) = $event)),
                      rows: "3"
                    }, null, 8, ["modelValue"])
                  ])),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.translate("Autoresponder name")), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "form-control form-control",
                  name: "name",
                  placeholder: _ctx.translate('Autoresponder name'),
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.destination_tag) = $event))
                }, null, 8, _hoisted_15), [
                  [_vModelText, _ctx.data.destination_tag]
                ]),
                _createElementVNode("small", null, _toDisplayString(_ctx.translate("Create a title for the new Autoresponder")), 1)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("button", _hoisted_17, _toDisplayString(_ctx.translate("cancel")), 1),
                _createElementVNode("button", {
                  ref: "submitButtonRef",
                  type: "submit",
                  class: "btn btn-sm btn-primary",
                  "data-kt-menu-dismiss": "true",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.copy && _ctx.copy(...args)))
                }, _toDisplayString(_ctx.translate("Copy")), 513)
              ])
            ])
          ])
        ])
      ])
    ])
  ], 512))
}