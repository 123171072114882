
import { defineComponent, computed, ref } from "vue";
import FileViewer from "@/components/FileViewer.vue";
import { translate } from "@/core/services/HelpersFun";

export default defineComponent({
  name: "blocks-node",
  components: {
    FileViewer,
  },
  props: {
    parentId: {
      type: Number,
      default: undefined,
    },
    data: {
      type: Object,
      required: true,
    },
    props: {
      type: Object,
      default: () => ({
        label: "label",
        expand: "expand",
        children: "children",
        key: "id",
      }),
    },
    collapsable: {
      type: Boolean,
      default: false,
    },
    renderContent: Function,
    labelWidth: [String, Number],
    labelClassName: [String, Function],
  },

  setup(props, { emit }) {
    let isLeaf = computed(
      () =>
        !(
          Array.isArray(props.data[props.props.children]) &&
          props.data[props.props.children].length > 0
        )
    );
    const addChild = (e) => {
      let data = { ...props.data };
      data.parent_id = props.parentId;
      emit("add-click", e, data, nodeContext);
    };

    let expanded = ref<boolean>(props.data[props.props.expand] && true);

    let nodeClass = computed(() => {
      return {
        "org-tree-node": true,
        "is-leaf": isLeaf.value,
        collapsed: !isLeaf.value && props.collapsable && !expanded.value,
      };
    });

    let innerLabelClass = computed(() => {
      let labelClassName =
        typeof props.labelClassName == "function"
          ? props.labelClassName(props.data)
          : props.labelClassName;

      return {
        "org-tree-node-label-inner": true,
        [labelClassName]: !!labelClassName,
      };
    });

    let nodeExpandBtnClass = computed(() => {
      return {
        "org-tree-node-btn": true,
        expanded: !!expanded.value,
      };
    });

    const toggleExpand = () => {
      expanded.value = !expanded.value;
    };

    const onExpandBtnClick = (e: Event) => {
      toggleExpand();
      emit("node-expand", e, props.data, nodeContext);
    };

    const nodeContext = {
      isExpanded: () => expanded.value,
      toggleExpand,
    };

    const nodeKey = (child) => {
      var key = child[props.props.key];

      if (typeof key === "function") {
        return key(child);
      }
      return key;
    };

    const enter = (event) => {
      const element = event;
      element.style.visibility = "hidden";
      element.style.height = "auto";
      const { height } = getComputedStyle(element);
      element.style.visibility = null;
      element.style.height = 0;
      setTimeout(() => {
        element.style.height = height;
      });
    };

    const afterEnter = (event) => {
      const element = event;
      element.style.height = "auto";
    };

    const leave = (event) => {
      const element = event;
      const { height } = getComputedStyle(element);
      element.style.height = height;
      setTimeout(() => {
        element.style.height = 0;
      });
    };

    return {
      enter,
      afterEnter,
      leave,
      nodeClass,
      innerLabelClass,
      isLeaf,
      nodeExpandBtnClass,
      onExpandBtnClick,
      expanded,
      nodeContext,
      nodeKey,
      addChild,
      translate,
    };
  },
});
