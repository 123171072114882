
import { defineComponent, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { translate } from "@/core/services/HelpersFun";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
  name: "dialog-edit-tag",
  props: {
    tag: {
      type: String,
      default: "",
    },
    bot_id: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const data = ref({
      bot_id: props.bot_id,
      tag: props.tag,
      newTag: "",
    });

    watch(props, () => {
      data.value.bot_id = props.bot_id;
      data.value.tag = props.tag;
      data.value.newTag = data.value.tag;
    });

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const editTagModal = ref<null | HTMLElement>(null);

    const editTag = () => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.disabled = true;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ApiService.post(`bot/${data.value.bot_id}/bot-menu/edit-secretary`, {
        tag: data.value.tag,
        new_name: data.value.newTag,
      })
        .then(() => {
          emit("update", true);
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          hideModal(editTagModal.value);
        })
        .catch((e) => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          store.commit(Mutations.SET_ERROR, e.response.data);
          let error = e.response.data.message;
          ElNotification({
            title: translate("error"),
            message: error,
            type: "error",
          });
        });
    };

    return {
      translate,
      data,
      editTag,
      submitButtonRef,
      editTagModal,
    };
  },
});
