
import { defineComponent, onMounted, ref, watch, computed } from "vue";

import ApiService from "@/core/services/ApiService";
import { hideModal } from "@/core/helpers/dom";
import Emoji from "@/components/Emoji.vue";
import UploadData from "@/components/uploadFiles/UploadFile.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "crud-secretary-menu-modal",
  emit: ["submitMenu", "editMenu"],
  components: {
    Emoji,
    UploadData,
  },
  props: {
    type: {
      type: String,
    },
    editData: {
      type: Object,
      default: undefined,
    },
    version: {
      type: Number,
      default: 0,
    },
    parentId: {
      type: Number,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const sendOnlyOnceFlag = ref(false);

    const menuData = ref({
      title: "",
      menu_key: "",
      send_message_after: sendOnlyOnceFlag.value? -1: 0,
      sort: 0,
      action: [
        {
          type: "text",
          text: "",
          file: undefined,
          with_file: false,
        },
      ],
    }) as any;

    const createSecretaryRef = ref();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);

    // send welcome message

    const fileUploadedCallBack = (file, activeIndex) => {
      menuData.value.action[activeIndex].file = file.path;
      menuData.value.action[activeIndex].type =
        file.file_type === "message" ? "text" : file.file_type;
         if (file.origin_file_name)
        menuData.value.action[activeIndex].origin_file_name =
          file.origin_file_name;
    };

    // add - remove - Menu Items
    const addNewItems = () => {
      menuData.value.action.push({
        type: "text",
        text: "",
        file: undefined,
        with_file: false,
      });
    };

    const removeItem = (index) => {
      menuData.value.action.splice(index, 1);
    };

    // add to phone book
    const addToPhonebookFlag = ref(false);
    const addToPhonebook = ref(undefined);
    const phonebooks = ref([]);
    const getPhonebooks = () => {
      let endPointUrl = "phonebook/all";
      ApiService.setHeader();
      ApiService.get(endPointUrl).then(({ data }) => {
        phonebooks.value = data.data.phonebooks;
      });
    };

    const check = ref(1);
    watch(props, () => {
      if (props.type === "create") {
        check.value++;
        // create new menu
        menuData.value = {
          title: "",
          menu_key: "",
          send_message_after:sendOnlyOnceFlag.value? -1: 0,
          sort: 0,
          check: check.value,
          action: [
            {
              type: "text",
              text: "",
              file: undefined,
              with_file: false,
            },
          ],
        };
      } else {
        // edit menu
        const data = JSON.parse(JSON.stringify(props.editData));
        menuData.value = data;
        if (menuData.value.send_message_after === -1) sendOnlyOnceFlag.value = true;
        if (menuData.value.add_to_phonebook) {
          addToPhonebookFlag.value = true;
          addToPhonebook.value = menuData.value.add_to_phonebook;
        }

        // if (menuData.value.action) {
        //   menuData.value.action.map((item) => {
        //     item.file = null;
        //   });
        // }
      }

 
    });

    const info = computed(() => {
      if (props.type === "create") {
        return {
          title: translate("Create Menu"),
        };
      } else if (props.type === "edit") {
        return {
          title: translate("Edit Menu"),
        };
      } else {
        return translate("Create your Autoresponder welcome message");
      }
    });

    const errorsBag = ref({
      message: translate("Submitted entries are invalid"),
      data: {},
    });

    // submit
    const submit = (e) => {
      e.preventDefault();
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      let data = { ...menuData.value };

      let checkTypeTextIsEmpty = 0;
      data.action.forEach((item, index) => {
        if (item.with_file) item.type = "file";
        if (item.type === "text" && (item.text === "" || item.text === null)) {
          checkTypeTextIsEmpty++;
          errorsBag.value.data[`message_${index}`] = [
            translate("The message field must contain a value"),
          ];

        }
      });

      if (checkTypeTextIsEmpty > 0) {
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;
          submitButtonRef.value?.removeAttribute("data-kt-indicator");
        }
        return false;
      }

      data.action = JSON.parse(JSON.stringify(data.action));
      data.parent_id = props.parentId;
      if (addToPhonebookFlag.value && addToPhonebook.value)
        data.add_to_phonebook = addToPhonebook.value;

      setTimeout(() => {
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;
          submitButtonRef.value?.removeAttribute("data-kt-indicator");
        }
        if (createSecretaryRef.value) {
          hideModal(createSecretaryRef.value);
        }
      }, 5000);

      if (!sendOnlyOnceFlag.value) data.send_message_after = 0;
      if (sendOnlyOnceFlag.value) data.send_message_after = -1;
      // edit
      if (props.type === "edit") return emit("editMenu", data.id, data);
      // create
      emit("submitMenu", data);
    };

    watch(props, () => {
      if (!props.parentId && submitButtonRef.value) {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value?.removeAttribute("data-kt-indicator");
        hideModal(createSecretaryRef.value);
      }
      errorsBag.value = {
        message: translate("Submitted entries are invalid"),
        data: {},
      };
    });

    onMounted(() => {
      getPhonebooks();
    });

    const emojiHelper = ref({
      activeIndex: 0 as number,
      activeIndent: 0 as number,
    });

    const addEmoji = (emoji) => {
      menuData.value.action[emojiHelper.value.activeIndex].text += emoji;
    };

    return {
      menuData,
      submit,
      close,

      // menu actions
      addNewItems,
      removeItem,

      // add to phone book
      addToPhonebookFlag,
      addToPhonebook,
      phonebooks,

      // send welcome message
      sendOnlyOnceFlag,

      createSecretaryRef,
      submitButtonRef,
      info,
      translate,

      addEmoji,
      emojiHelper,
      fileUploadedCallBack,
      errorsBag,
    };
  },
});
