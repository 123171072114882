
import { defineComponent, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { translate } from "@/core/services/HelpersFun";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
  name: "dialog-settings-tag",
  props: {
    tag: {
      type: String,
      default: "",
    },
    bot_id: {
      type: String,
      default: "",
    },
    footer: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const data = ref({
      bot_id: props.bot_id,
      tag: props.tag,
      footer: props.footer,
    });

    watch(props, () => {
      data.value.bot_id = props.bot_id;
      data.value.tag = props.tag;
      data.value.footer = props.footer;
    });

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const changeSettingsModal = ref<null | HTMLElement>(null);

    const editTag = () => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.disabled = true;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      let dataHolder = {};
      dataHolder["bot_setting"] = { secretary_footer: data.value.footer };
      dataHolder["tag"] = data.value.tag;
      if (data.value.footer !== "")
        ApiService.post(`bot/${data.value.bot_id}/change-setting`, dataHolder)
          .then(() => {
            emit("update", true);
            if (submitButtonRef.value) {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value?.removeAttribute("data-kt-indicator");
            }
            hideModal(changeSettingsModal.value);
          })
          .catch((e) => {
            if (submitButtonRef.value) {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value?.removeAttribute("data-kt-indicator");
            }
            store.commit(Mutations.SET_ERROR, e.response.data);
            let error = e.response.data.message;
            ElNotification({
              title: translate("error"),
              message: error,
              type: "error",
            });
          });
    };

    const addVariable = (variable) => {
      data.value.footer += variable;
    };

    return {
      translate,
      data,
      editTag,
      submitButtonRef,
      changeSettingsModal,
      addVariable,
    };
  },
});
