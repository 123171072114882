import { defineStore } from "pinia";
import { computed, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
export const Secretary = defineStore("secretaryinfo", () => {
  const store = useStore();
  // state
  const secretaryList = ref();
  const tryCounter = ref();
  const loadingSecretaryList = ref(false);

  const fetchSecretary = async (endPointApi = "Autoresponder") => {
    loadingSecretaryList.value = true;
    const endPoint =
      endPointApi === "Autoresponder"
        ? "bot/all-secretary?with_platforms=true"
        : "admin/secretary/list?with_platforms=true";

    try {
      const { data } = await ApiService.get(endPoint);
      secretaryList.value = data.data.secretaries;
      tryCounter.value = 0;
    } catch (error) {
      if (tryCounter.value < 3) {
        tryCounter.value++;
        await fetchSecretary();
      }
      if (error.response && error.response.data)
        store.commit(Mutations.SET_ERROR, error.response.data);
    } finally {
      loadingSecretaryList.value = false;
    }
  };

  const deleteSecretary = (bot_id, tag) => {
    ApiService.post(`bot/${bot_id}/bot-menu/delete-secretary`, { tag })
      .then(() => {
        fetchSecretary();
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data);
      });
  };

  return {
    loadingSecretaryList,
    secretaryList,
    deleteSecretary,
    fetchSecretary,
  };
});
